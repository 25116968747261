<template>
  <section class="mt-2 view-content">
     <b-overlay :show="loading" rounded="sm">
      <div v-if="opened" style="position: relative;">

        <b-card class="card-message">
          <template v-slot:header>
            <h4 class="m-0 p-0">{{ message.subject }}</h4>
          </template>

          <UserInfo
            image="/img/default-avatar.png"
            :primary="message.creator.email"
            :secondary="createdTime"
          ></UserInfo>

          <hr class="pb-0 mb-0 mt-2" />

          <div class="mt-2">
            <span v-for="(item, index) in message.attachments" :key="index">
              <Attachment
                :item="item"
                :index="index"
                :message_uuid="message_uuid"
              ></Attachment>
            </span>
            <BodyText :body="message.body"></BodyText>
          </div>

          <template v-slot:footer>
            <a @click="show = true" class="clickable"><i class="ml-2 fal fa-info-circle"></i> {{$t("DETAILS")}}</a>
          </template>
        </b-card>


        <b-sidebar
          id="message-information"
          v-model="show"
          right
          no-slide
          shadow
          backdrop
          v-if="message.settings"
        >
          <div class="px-3 py-2">
            {{ $t("MESSAGE_DETAILS") }}

            <hr class="pb-0 mb-0 mt-2 mb-3" />

            <h6 class="mb-0 no-uppercase">{{ $t("MESSAGE_UUID") }}</h6>
            {{ message.message_uuid }}

            <div v-if="message.creator">

              <h6 class="mt-3 mb-0 no-uppercase">{{ $t("CREATOR_UUID") }}</h6>
              {{ message.creator.user_uuid }}
            </div>

            <h6 class="mt-3 mb-0 no-uppercase">{{ $t("MESSAGE_VERSION") }}</h6>
            {{ message.version }}

                   <h6 class="mt-3 mb-0 no-uppercase">
              {{ $t("LOA.TITLE_PARTICIPANT") }}
            </h6>
            <i
              class="fal fa-info-square"
              v-b-tooltip.hover
              :title="$t('LOA.INFO.' + message.settings.loa_level)"
            ></i>
            LOA{{ message.settings.loa_level }}

            <h6 class="mt-3 mb-0 no-uppercase">{{ $t("TYPE") }}</h6>
            {{ $t("MESSAGES.TYPE." + message.message_type) }}

            <h6 class="mt-3 mb-0 no-uppercase">{{ $t("RESPONSE") }}</h6>
            {{ $t("MESSAGES.REQUESTED_RESPONSE") }}

            <hr class="pb-0 mb-0 mt-2" />
          </div>
        </b-sidebar>
      </div>

      <div
        v-if="!opened"
        class="mt-2"
      >     
         <b-card class="card-message text-center">
          <i class="fal fa-exclamation-circle"></i>
          {{ $t(errorMessage) }}
          </b-card>
      </div>
     </b-overlay>

  </section>
</template>
<script>
import UserInfo from "@/components/Layout/UserInfo";
import Attachment from "@/components/Read/Attachment";
import BodyText from "@/components/Read/BodyText";
export default {
  props: ["message_uuid"],
  components: {
    Attachment,
    UserInfo, 
    BodyText
  },
  data() {
    return {
      show: false,
      opened: false,
      loading: true,
      email: "",
      errorMessage: "",
      message: {},
      responded_message_id: ""
    };
  },
  methods: {
    getInformation: function() {
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/message/uuid/open",
          {
            message_uuid: this.message_uuid,
          }
        )
        .then((response) => {
          self.message = response.data.message;
          self.opened = true;
          self.loading = false;
        })
        .catch((error) => {
          self.loading = false;
          self.opened = false;
          if(error.toString() == "Error: fail_4_4") return;
          if (
            error.response.data == "ERROR.COULD_NOT_OPEN" ||
            error.response.data == "ERROR.RECALLED" ||
            error.response.data == "ERROR.REMOVED"
          ) {
            self.errorMessage = error.response.data;
          } else {
            self.$noty.error(self.$t(error.response.data));
          }
        });
    }
  },
  computed: {
    createdTime() {
      return this.$luxonDateTime.fromISO(this.message.created_date).toLocaleString(this.$luxonDateTime.DATETIME_MED);
    }
  },
  mounted: function() {
    this.getInformation();
  },
};
</script>
<style></style>
